import Formatter from 'src/formatter/Formatter';
import Tokenizer from 'src/lexer/Tokenizer';
import { dedupe } from 'src/utils';

const reservedFunctions = {
  // https://jakewheat.github.io/sql-overview/sql-2008-foundation-grammar.html#_6_9_set_function_specification
  set: ['GROUPING'],
  // https://jakewheat.github.io/sql-overview/sql-2008-foundation-grammar.html#_6_10_window_function
  window: ['RANK', 'DENSE_RANK', 'PERCENT_RANK', 'CUME_DIST', 'ROW_NUMBER'],
  // https://jakewheat.github.io/sql-overview/sql-2008-foundation-grammar.html#_6_27_numeric_value_function
  numeric: [
    'POSITION',
    'OCCURRENCES_REGEX',
    'POSITION_REGEX',
    'EXTRACT',
    'CHAR_LENGTH',
    'CHARACTER_LENGTH',
    'OCTET_LENGTH',
    'CARDINALITY',
    'ABS',
    'MOD',
    'LN',
    'EXP',
    'POWER',
    'SQRT',
    'FLOOR',
    'CEIL',
    'CEILING',
    'WIDTH_BUCKET',
  ],
  // https://jakewheat.github.io/sql-overview/sql-2008-foundation-grammar.html#_6_29_string_value_function
  string: [
    'SUBSTRING',
    'SUBSTRING_REGEX',
    'UPPER',
    'LOWER',
    'CONVERT',
    'TRANSLATE',
    'TRANSLATE_REGEX',
    'TRIM',
    'OVERLAY',
    'NORMALIZE',
    'SPECIFICTYPE',
  ],
  // https://jakewheat.github.io/sql-overview/sql-2008-foundation-grammar.html#_6_31_datetime_value_function
  datetime: ['CURRENT_DATE', 'CURRENT_TIME', 'LOCALTIME', 'CURRENT_TIMESTAMP', 'LOCALTIMESTAMP'],
  // https://jakewheat.github.io/sql-overview/sql-2008-foundation-grammar.html#_6_38_multiset_value_function
  // SET serves multiple roles: a SET() function and a SET keyword e.g. in UPDATE table SET ...
  // multiset: ['SET'], (disabled for now)
  // https://jakewheat.github.io/sql-overview/sql-2008-foundation-grammar.html#_10_9_aggregate_function
  aggregate: [
    'COUNT',
    'AVG',
    'MAX',
    'MIN',
    'SUM',
    'EVERY',
    'ANY',
    'SOME',
    'STDDEV_POP',
    'STDDEV_SAMP',
    'VAR_SAMP',
    'VAR_POP',
    'COLLECT',
    'FUSION',
    'INTERSECTION',
    'COVAR_POP',
    'COVAR_SAMP',
    'CORR',
    'REGR_SLOPE',
    'REGR_INTERCEPT',
    'REGR_COUNT',
    'REGR_R2',
    'REGR_AVGX',
    'REGR_AVGY',
    'REGR_SXX',
    'REGR_SYY',
    'REGR_SXY',
    'PERCENTILE_CONT',
    'PERCENTILE_DISC',
  ],
  // CAST is a pretty complex case, involving multiple forms:
  // - CAST(col AS int)
  // - CAST(...) WITH ...
  // - CAST FROM int
  // - CREATE CAST(mycol AS int) WITH ...
  // cast: ['CAST'], (disabled for now)
};

/**
 * Priority 5 (last)
 * Full list of reserved words
 * any words that are in a higher priority are removed
 */
// https://jakewheat.github.io/sql-overview/sql-2008-foundation-grammar.html#reserved-word
// (minus the function names listed above)
const reservedKeywords = [
  'ALL',
  'ALLOCATE',
  'ALTER',
  'ARE',
  'ARRAY',
  'AS',
  'ASENSITIVE',
  'ASYMMETRIC',
  'AT',
  'ATOMIC',
  'AUTHORIZATION',
  'BEGIN',
  'BETWEEN',
  'BIGINT',
  'BINARY',
  'BLOB',
  'BOOLEAN',
  'BOTH',
  'BY',
  'CALL',
  'CALLED',
  'CASCADED',
  'CAST',
  'CHAR',
  'CHARACTER',
  'CHECK',
  'CLOB',
  'CLOSE',
  'COALESCE',
  'COLLATE',
  'COLUMN',
  'COMMIT',
  'CONDITION',
  'CONNECT',
  'CONSTRAINT',
  'CORRESPONDING',
  'CREATE',
  'CROSS',
  'CUBE',
  'CURRENT',
  'CURRENT_CATALOG',
  'CURRENT_DEFAULT_TRANSFORM_GROUP',
  'CURRENT_PATH',
  'CURRENT_ROLE',
  'CURRENT_SCHEMA',
  'CURRENT_TRANSFORM_GROUP_FOR_TYPE',
  'CURRENT_USER',
  'CURSOR',
  'CYCLE',
  'DATE',
  'DAY',
  'DEALLOCATE',
  'DEC',
  'DECIMAL',
  'DECLARE',
  'DEFAULT',
  'DELETE',
  'DEREF',
  'DESCRIBE',
  'DETERMINISTIC',
  'DISCONNECT',
  'DISTINCT',
  'DOUBLE',
  'DROP',
  'DYNAMIC',
  'EACH',
  'ELEMENT',
  'END-EXEC',
  'ESCAPE',
  'EXCEPT',
  'EXEC',
  'EXECUTE',
  'EXISTS',
  'EXTERNAL',
  'FALSE',
  'FETCH',
  'FILTER',
  'FLOAT',
  'FOR',
  'FOREIGN',
  'FREE',
  'FROM',
  'FULL',
  'FUNCTION',
  'GET',
  'GLOBAL',
  'GRANT',
  'GROUP',
  'HAVING',
  'HOLD',
  'HOUR',
  'IDENTITY',
  'IN',
  'INDICATOR',
  'INNER',
  'INOUT',
  'INSENSITIVE',
  'INSERT',
  'INT',
  'INTEGER',
  'INTERSECT',
  'INTERVAL',
  'INTO',
  'IS',
  'LANGUAGE',
  'LARGE',
  'LATERAL',
  'LEADING',
  'LEFT',
  'LIKE',
  'LIKE_REGEX',
  'LOCAL',
  'MATCH',
  'MEMBER',
  'MERGE',
  'METHOD',
  'MINUTE',
  'MODIFIES',
  'MODULE',
  'MONTH',
  'MULTISET',
  'NATIONAL',
  'NATURAL',
  'NCHAR',
  'NCLOB',
  'NEW',
  'NO',
  'NONE',
  'NOT',
  'NULL',
  'NULLIF',
  'NUMERIC',
  'OF',
  'OLD',
  'ON DELETE',
  'ON UPDATE',
  'ONLY',
  'OPEN',
  'ORDER',
  'OUT',
  'OUTER',
  'OVER',
  'OVERLAPS',
  'PARAMETER',
  'PARTITION',
  'PRECISION',
  'PREPARE',
  'PRIMARY',
  'PROCEDURE',
  'RANGE',
  'READS',
  'REAL',
  'RECURSIVE',
  'REF',
  'REFERENCES',
  'REFERENCING',
  'RELEASE',
  'RESULT',
  'RETURN',
  'RETURNS',
  'REVOKE',
  'RIGHT',
  'ROLLBACK',
  'ROLLUP',
  'ROW',
  'ROWS',
  'SAVEPOINT',
  'SCOPE',
  'SCROLL',
  'SEARCH',
  'SECOND',
  'SELECT',
  'SENSITIVE',
  'SESSION_USER',
  'SET',
  'SIMILAR',
  'SMALLINT',
  'SPECIFIC',
  'SQL',
  'SQLEXCEPTION',
  'SQLSTATE',
  'SQLWARNING',
  'START',
  'STATIC',
  'SUBMULTISET',
  'SYMMETRIC',
  'SYSTEM',
  'SYSTEM_USER',
  'TABLE',
  'TABLESAMPLE',
  'THEN',
  'TIME',
  'TIMESTAMP',
  'TIMEZONE_HOUR',
  'TIMEZONE_MINUTE',
  'TO',
  'TRAILING',
  'TRANSLATION',
  'TREAT',
  'TRIGGER',
  'TRUE',
  'UESCAPE',
  'UNION',
  'UNIQUE',
  'UNKNOWN',
  'UNNEST',
  'UPDATE',
  'USER',
  'VALUE',
  'VALUES',
  'VARBINARY',
  'VARCHAR',
  'VARYING',
  'WHENEVER',
  'WINDOW',
  'WITHIN',
  'WITHOUT',
  'YEAR',
];

/**
 * Priority 1 (first)
 * keywords that begin a new statement
 * will begin new indented block
 */
const reservedCommands = [
  'ADD',
  'ALTER COLUMN',
  'ALTER TABLE',
  'CREATE TABLE',
  'DROP TABLE',
  'DELETE FROM',
  'FETCH FIRST',
  'FETCH NEXT',
  'FETCH PRIOR',
  'FETCH LAST',
  'FETCH ABSOLUTE',
  'FETCH RELATIVE',
  'FROM',
  'GROUP BY',
  'HAVING',
  'INSERT INTO',
  'LIMIT',
  'OFFSET',
  'ORDER BY',
  'SELECT',
  'SET SCHEMA',
  'SET',
  'UPDATE',
  'VALUES',
  'WHERE',
  'WITH',
  'WINDOW',
  'PARTITION BY',
];

const reservedBinaryCommands = [
  'INTERSECT',
  'INTERSECT ALL',
  'INTERSECT DISTINCT',
  'UNION',
  'UNION ALL',
  'UNION DISTINCT',
  'EXCEPT',
  'EXCEPT ALL',
  'EXCEPT DISTINCT',
];

const reservedJoins = [
  'JOIN',
  'INNER JOIN',
  'LEFT JOIN',
  'LEFT OUTER JOIN',
  'RIGHT JOIN',
  'RIGHT OUTER JOIN',
  'FULL JOIN',
  'FULL OUTER JOIN',
  'CROSS JOIN',
  'NATURAL JOIN',
];

/**
 * Priority 3
 * keywords that follow a previous Statement, must be attached to subsequent data
 * can be fully inline or on newline with optional indent
 */
const reservedDependentClauses = ['WHEN', 'ELSE'];

export default class SqlFormatter extends Formatter {
  static operators = [];

  tokenizer() {
    return new Tokenizer({
      reservedCommands,
      reservedBinaryCommands,
      reservedJoins,
      reservedDependentClauses,
      reservedKeywords: dedupe([...reservedKeywords, ...Object.values(reservedFunctions).flat()]),
      stringTypes: [{ quote: "''", prefixes: ['X'] }],
      identTypes: [`""`, '``'],
      positionalParams: true,
    });
  }
}
